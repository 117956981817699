import React, { Fragment } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { FORM_ERROR } from 'final-form'

import { request } from '../../../api/apiCore'
import { Container } from '../../../components/Container'
import { UsersForm } from '../../../components/forms/admin/UsersForm'
import Breadcrumb from '../../../components/ui/Breadcrumb'
import HeadingTag from '../../../components/ui/HeadingTag'
import { allowedModules } from '../../../configs/users'
import { convertDataToBreadcrumbRoute, urlSearchParamsData } from '../../../helpers/helpers'

const fetchUser = userId => request({ url: `/admin/gob_users/${userId}.json`, method: 'GET' })

export const UserEdit = () => {
  const { institution_gobpe_id } = urlSearchParamsData()
  const { id: userId } = useParams()

  const { data: user, status } = useQuery(['user', userId], () => fetchUser(userId))
  const navigate = useNavigate()
  const breadcrumbData = convertDataToBreadcrumbRoute('Editar usuario')

  const onSubmit = async values => {
    try {
      const data = {
        ...values,
        allowed_modules: values.allowed_modules ? allowedModules : null,
        ...(institution_gobpe_id && { institution_gobpe_id })
      }
      await request({ url: `/admin/gob_users/${userId}.json`, data, method: 'PATCH' })
      navigate(
        institution_gobpe_id
          ? '/admin/areas-y-usuarios?institution_gobpe_id=' + institution_gobpe_id
          : '/admin/areas-y-usuarios'
      )
    } catch (error) {
      return { [FORM_ERROR]: 'Ocurrio un error al registrar el área.' }
    }
  }

  if (status !== 'success') return null

  return (
    <Fragment>
      <Breadcrumb routesKey="users" data={breadcrumbData} />
      <Container className="py-16 pl-20 px-6">
        <HeadingTag title="Editar usuario" className="mb-4 font-bold text-3xl" />
        <UsersForm
          initialValues={{ ...user, allowed_modules: user.allowed_modules?.length ? allowedModules[0].value : '' }}
          onSubmit={onSubmit}
        />
      </Container>
    </Fragment>
  )
}
