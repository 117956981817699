import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'

import EditIcon from '../../../assets/images/edit_icon.svg'
import ViewIcon from '../../../assets/images/view_icon.svg'
import { urlSearchParamsData } from '../../../helpers/helpers'
import SwitchField from '../../fields/SwitchField'
import { Pagination } from '../../ui/Pagination'

const userRoles = {
  admin_carpeta: 'Admin carpeta',
  admin: 'Admin',
  coordinator: 'Coordinador',
  collaborator: 'Colaborador'
}

const getUserAccess = user => {
  if (user.role !== 'collaborator') return 'Todos los servicios'
  if (!user.allowed_modules?.length) return 'Ninguno'
  return user.allowed_modules.map(module => module.label).join(', ')
}

const TableActions = ({ user }) => {
  const urlSearchParams = urlSearchParamsData()

  return (
    <>
      <IconButton type="button" className="!p-0 hover:!bg-transparent" disableRipple>
        <img src={ViewIcon} alt="view-icon" />
      </IconButton>
      <IconButton
        component={Link}
        to={
          urlSearchParams.institution_gobpe_id
            ? `/admin/gob_users/edit/${user.id}?institution_gobpe_id=` + urlSearchParams.institution_gobpe_id
            : `/admin/gob_users/edit/${user.id}`
        }
        className="!p-0 !ml-5 hover:!bg-transparent"
        disableRipple
      >
        <img src={EditIcon} alt="edit-icon" />
      </IconButton>
    </>
  )
}

const UsersTable = ({ users = [], totalPages = 0, onStatusChange }) => (
  <>
    <section className="overflow-x-auto overflow-y-hidden">
      <table className="admin-table">
        <thead>
          <tr>
            <th>Nombre</th>
            <th className="w-43.5">DNI</th>
            <th>Perfil</th>
            <th>Accesos</th>
            <th>Estado</th>
            <th className="text-right">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <Fragment key={user.id}>
              <tr>
                <td>{user.name}</td>
                <td>{user.document_number}</td>
                <td>{userRoles[user.role]}</td>
                <td>{getUserAccess(user)}</td>
                <td>
                  <SwitchField
                    name="status"
                    margin="none"
                    description={user.active ? 'Activo' : 'Desactivado'}
                    checked={user.active}
                    onChange={() => onStatusChange(user.id)}
                  />
                </td>
                <td className="text-right">
                  <TableActions user={user} />
                </td>
              </tr>
              <tr className="h-2"></tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </section>
    <Pagination numPages={Math.ceil(totalPages)} paginationLabel="gobuser_page" className="mt-4" />
  </>
)

UsersTable.propTypes = {
  users: PropTypes.array,
  totalPages: PropTypes.number,
  onStatusChange: PropTypes.func
}

TableActions.propTypes = {
  user: PropTypes.object
}

export default UsersTable
