import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Form } from 'react-final-form'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import PropTypes from 'prop-types'

import InformationIcon from '../../../assets/images/information_message_icon.svg'
import { useAuth } from '../../../auth/Auth'
import { allowedModules } from '../../../configs/users'
import { DOCUMENT_TYPES, PASSWORD_RULES } from '../../../constants/globals'
import { dataToOptions, urlSearchParamsData } from '../../../helpers/helpers'
import { useAreasData } from '../../../hooks/useAreas'
import PasswordField from '../../fields/PasswordField'
import SaveButton from '../../fields/SaveButton'
import SelectField from '../../fields/SelectField'
import TextField from '../../fields/TextField'
import * as validations from '../../fields/Validations'
import Value from '../../fields/Value'
import WhenFieldChanges from '../../fields/WhenFieldChanges'
import AlertCard from '../../ui/AlertCard'

const allowedRolesByRol = {
  admin_carpeta: ['admin_carpeta', 'admin', 'coordinator', 'collaborator'],
  admin: ['admin', 'coordinator', 'collaborator'],
  coordinator: ['collaborator']
}

const gobUserRoles = [
  { value: 'admin_carpeta', label: 'Admin Carpeta' },
  { value: 'admin', label: 'Admin' },
  { value: 'coordinator', label: 'Coordinador' },
  { value: 'collaborator', label: 'Colaborador' }
]

const getAlertContent = gobUserExists => {
  if (gobUserExists)
    return (
      <span>
        Este usuario ya existe en Gob.pe. Se le <b>brindará acceso a Gestión de la Carpeta Ciudadana</b> y para ingresar
        deberá usar el mismo correo y contraseña.
      </span>
    )

  return (
    <span>
      El correo no está asociado a ninguna cuenta; <b>procede con su creación.</b>
    </span>
  )
}

export const UsersForm = forwardRef(({ initialValues = {}, onSubmit, create }, ref) => {
  const { admin } = useAuth()
  const { institution_gobpe_id } = urlSearchParamsData()
  const { data: areas, isLoading: isAreasLoading } = useAreasData()
  const [gobUser, setGobUser] = useState({ verifiedEmail: false, exists: false })

  const rolesByAdminRole = gobUserRoles.filter(rol => allowedRolesByRol[admin.role]?.includes(rol.value))

  const buttonLabel =
    create && !gobUser.verifiedEmail
      ? 'Validar'
      : gobUser.exists
      ? 'Brindar acceso'
      : create
      ? 'Crear usuario'
      : 'Guardar cambios'

  useImperativeHandle(ref, () => {
    return { gobUser, setGobUser }
  })

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      subscription={{ pristine: true, submitting: true, submitError: true }}
      render={({ handleSubmit, submitError, submitting, pristine }) => (
        <form onSubmit={handleSubmit} className="max-w-114">
          <TextField
            name="email"
            label="Correo electrónico"
            hint="Se validará si el correo ya está asociado a una cuenta Gob.pe"
            labelClassName=""
            size="full"
            margin={submitError ? 'lowdense' : gobUser.verifiedEmail ? 'dense' : 'normal'}
            disabled={gobUser.verifiedEmail}
            validate={validations.mix(validations.isEmail(), validations.required())}
          />
          {create && gobUser.verifiedEmail && (
            <AlertCard
              icon={<ReactSVG src={InformationIcon} />}
              title="Importante"
              content={getAlertContent(gobUser.exists)}
              type="infoDocument"
              className="mb-6 rounded"
            />
          )}
          {(!create || gobUser.verifiedEmail) && (
            <>
              <TextField
                label="Nombre y apellidos"
                name="name"
                size="full"
                disabled={gobUser.exists}
                validate={validations.mix(validations.required(), validations.isLetter())}
              />
              <SelectField
                size="full"
                name="document_type_code"
                label="Tipo de documento"
                defaultOption="Seleccione un tipo de documento"
                options={DOCUMENT_TYPES}
                disabled={gobUser.exists}
                validate={validations.required()}
              />
              <TextField
                label="Número de documento"
                name="document_number"
                size="full"
                disabled={gobUser.exists}
                validate={validations.mix(
                  validations.minLength(8),
                  validations.maxLength(8),
                  validations.isNumber(),
                  validations.required()
                )}
              />
              {create && !gobUser.exists && (
                <PasswordField
                  label="Contraseña"
                  name="password"
                  labelClassName="mb-2"
                  checkboxLabel="Mostrar contraseña"
                  checkboxName="show_password"
                  checkboxClassName="pb-6"
                  passwordRules={PASSWORD_RULES}
                  size="full"
                  validate={validations.mix(
                    validations.required(),
                    validations.minLength(8),
                    validations.minOneLetter(),
                    validations.minOneNumber()
                  )}
                />
              )}
              <SelectField
                name="area_id"
                label="Área interna"
                margin="dense"
                size="full"
                defaultOption="Seleccionar área"
                options={dataToOptions(areas || [], ['id', 'name'])}
                disabled={isAreasLoading}
                validate={validations.required()}
              />
              <SelectField
                name="role"
                label="Tipo de usuario"
                className="block"
                margin="dense"
                size="full"
                defaultOption="Seleccionar tipo"
                options={rolesByAdminRole}
                validate={validations.required()}
              />
            </>
          )}
          <WhenFieldChanges field="role" set="allowed_modules" to={null} />
          <Value name="role">
            {({ value }) =>
              value === 'collaborator' && (
                <SelectField
                  name="allowed_modules"
                  label="Módulos a los que tiene acceso"
                  size="full"
                  options={allowedModules}
                  validate={validations.required()}
                />
              )
            }
          </Value>
          {submitError && <p className="text-red-300 text-sm mb-6">{submitError}</p>}
          <SaveButton label={buttonLabel} size="full" className="max-w-45" disabled={submitting || pristine} />
          <Link
            to={
              institution_gobpe_id
                ? `/admin/areas-y-usuarios?institution_gobpe_id=${institution_gobpe_id}`
                : '/admin/areas-y-usuarios'
            }
            className="font-bold text-blue-700 ml-10"
          >
            Cancelar
          </Link>
        </form>
      )}
    />
  )
})

UsersForm.displayName = 'UsersForm'

UsersForm.propTypes = {
  initialValues: PropTypes.object,
  create: PropTypes.bool,
  onSubmit: PropTypes.func
}
