import React, { useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import { FORM_ERROR } from 'final-form'

import { request } from '../../api/apiCore'
import ArrowBackIcon from '../../assets/images/arrow_back_icon.svg'
import { Container } from '../../components/Container'
import * as validations from '../../components/fields/Validations'
import PasswordChangeForm from '../../components/forms/PasswordChangeForm'
import ProfileEditForm from '../../components/forms/ProfileEditForm'
import RucNumberForm from '../../components/forms/RucNumberForm'
import { formatPhoneNumber, setItemToLocalStorage } from '../../helpers/helpers'
import { useUserData } from '../../hooks/useUsers'

const updateUser = (id, data) => request({ url: `/users/${id}.json`, data, method: 'PATCH' })
const verifyCode = (code, salt) => request({ url: '/registration/verify-code', data: { code, salt }, method: 'POST' })

export const ProfileEditPage = () => {
  const { userId, type } = useParams()
  const navigate = useNavigate()
  const { data: user, status } = useUserData(userId)
  const [timerConfig, setTimerConfig] = useState({ show: false, reset: false })
  const [pageData, setPageData] = useState({ email: '', phone_number: '', salt: '' })
  const formErrorMessage = { [FORM_ERROR]: 'Código ingresado es incorrecto o ya caducó' }
  const { executeRecaptcha } = useGoogleReCaptcha()

  if (!executeRecaptcha) console.info('Execute recaptcha not yet available')

  const handleRucFormSubmit = async ({ person_type, document_number, check_digit }) => {
    const data = { ruc: `${person_type}${document_number}${check_digit}` }
    const response = await updateUser(userId, data)

    if (!response.id) return { [FORM_ERROR]: 'Ocurrio un error al editar número de RUC' }

    setAlert({ type: 'success', text: 'Se editó el RUC exitosamente' })
    backToInitialPage()
  }

  const handleEmailForm = async ({ email }) => {
    const checkEmail = await request({
      url: '/registration/verify-email.json',
      data: { name: user.name, email },
      method: 'POST'
    })

    if (checkEmail.response?.data.error) return { [FORM_ERROR]: checkEmail.response.data.error }

    setPageData({ ...pageData, email, salt: checkEmail.salt })
    navigate(`/perfil/${userId}/editar/validar-correo`)
  }

  const handleEmailVerifyForm = async ({ code }) => {
    const checkCode = await verifyCode(code, pageData.salt)
    if (checkCode.response?.data.error) return formErrorMessage

    const response = await updateUser(userId, { email: pageData.email })
    if (!response.id) return { [FORM_ERROR]: 'Ocurrio un error al editar correo electrónico' }

    setAlert({ type: 'success', text: 'Se cambió el correo electrónico exitosamente' })
    backToInitialPage()
  }

  const handlePhoneNumberForm = async ({ phone_number }) => {
    const checkPhoneNumber = await request({
      url: '/registration/verify-phone-number.json',
      data: { name: user.name, phone_number },
      method: 'POST'
    })

    if (checkPhoneNumber.response?.data.error) return { [FORM_ERROR]: checkPhoneNumber.response.data.error }

    setPageData({ ...pageData, phone_number, salt: checkPhoneNumber.salt })
    navigate(`/perfil/${userId}/editar/validar-celular`)
  }

  const handlePhoneNumberVerifyForm = async ({ code }) => {
    const checkCode = await verifyCode(code, pageData.salt)
    if (checkCode.response?.data.error) return formErrorMessage

    const response = await updateUser(userId, { phone_number: pageData.phone_number })
    if (!response.id) return { [FORM_ERROR]: 'Ocurrio un error al editar número de celular' }

    setAlert({ type: 'success', text: 'Se cambió el número de celular exitosamente' })
    backToInitialPage()
  }

  const handleResendCode = async send_type => {
    setTimerConfig({ ...timerConfig, show: true })

    const response = await request({ url: '/registration/resend-code.json', data: { send_type }, method: 'POST' })
    if (!response) return { [FORM_ERROR]: 'Ocurrió un error al reenviar código' }

    setPageData({ ...pageData, salt: response.salt })
  }

  const handlePasswordForm = async ({ current_password, new_password }) => {
    const recaptcha_action = 'login'
    const recaptcha = await executeRecaptcha(recaptcha_action)
    const data = {
      document_type_code: user.document_type_code,
      document_number: user.document_number,
      password: current_password,
      recaptcha,
      recaptcha_action
    }

    const { token } = await request({ url: '/authentication', data, method: 'POST' })
    if (!token) return { [FORM_ERROR]: 'Contraseña actual incorrecta' }

    const response = await updateUser(userId, { password: new_password })
    if (!response.id) return { [FORM_ERROR]: 'Ocurrio un error al editar contraseña' }

    setAlert({ type: 'success', text: 'Se cambió la contraseña exitosamente' })
    backToInitialPage()
  }

  const setAlert = content => setItemToLocalStorage('actionAlert', JSON.stringify(content))

  const backToInitialPage = () => navigate('/perfil')

  if (user?.response?.data.errors) backToInitialPage()

  if (!['ruc', 'correo', 'validar-correo', 'celular', 'validar-celular', 'contrasena'].includes(type))
    backToInitialPage()

  if (['loading', 'error'].includes(status)) return null

  const pageForms = {
    ruc: (
      <RucNumberForm
        title="Editar RUC"
        subtitle="Tu número de RUC servirá para poder descargar los documentos que requieren este dato de manera obligatoria."
        subtitleClassName="pt-4 pb-6"
        initialValues={{ document_number: user.document_number }}
        handleSubmit={handleRucFormSubmit}
        handleCancel={backToInitialPage}
      />
    ),
    correo: (
      <ProfileEditForm
        title="Editar correo electrónico"
        subtitle="Tu correo electrónico sirve como una opción de autenticación adicional y para recuperar tu contraseña. Además nos sirve para comunicarnos contigo en caso detectemos actividad inusual en tu cuenta."
        fieldProps={{
          name: 'email',
          label: 'Nuevo correo electrónico',
          size: 'full'
        }}
        validate={validations.mix(validations.isEmail(), validations.required())}
        firstButtonLabel="Guardar cambios"
        secondButtonLabel="Regresar"
        handleSubmit={handleEmailForm}
        showSecondButton
        handleSecondButton={backToInitialPage}
      />
    ),
    'validar-correo': (
      <ProfileEditForm
        title="Validación de correo electrónico"
        subtitle={
          <>
            Hemos enviado un código al siguiente correo electrónico, será valido por 10 minutos:
            <span className="pl-1 md:pl-4 font-bold">{pageData.email}</span>
          </>
        }
        fieldProps={{
          name: 'code',
          label: 'Ingresa el código',
          size: 'full',
          margin: 'dense'
        }}
        validate={validations.mix(validations.isNumber(), validations.required())}
        showResendSection
        resendText="¿No recibiste el correo?"
        timer={timerConfig}
        setTimer={setTimerConfig}
        handleResend={() => handleResendCode('email')}
        firstButtonLabel="Continuar"
        secondButtonLabel="Cancelar"
        handleSubmit={handleEmailVerifyForm}
        showSecondButton
        handleSecondButton={backToInitialPage}
      />
    ),
    celular: (
      <ProfileEditForm
        title="Editar número de celular"
        subtitle="Tu número de celular sirve como una opción de autenticación adicional y para recuperar tu contraseña. Además nos sirve para comunicarnos contigo en caso detectemos actividad inusual en tu cuenta."
        fieldProps={{
          name: 'phone_number',
          label: 'Nuevo número de celular',
          size: 'full'
        }}
        validate={validations.mix(validations.isPeruvianCellphone(), validations.required())}
        firstButtonLabel="Guardar cambios"
        secondButtonLabel="Regresar"
        handleSubmit={handlePhoneNumberForm}
        showSecondButton
        handleSecondButton={backToInitialPage}
      />
    ),
    'validar-celular': (
      <ProfileEditForm
        title="Validación de número de celular"
        subtitle={
          <>
            Hemos enviado un código al siguiente número de celular, será valido por 10 minutos
            <span className="pl-2.5 font-bold">{formatPhoneNumber(pageData.phone_number)}</span>
          </>
        }
        fieldProps={{
          name: 'code',
          label: 'Ingresa el código',
          size: 'full',
          margin: 'dense'
        }}
        validate={validations.mix(validations.isNumber(), validations.required())}
        showResendSection
        resendText="¿No recibiste el código?"
        timer={timerConfig}
        setTimer={setTimerConfig}
        handleResend={() => handleResendCode('sms')}
        firstButtonLabel="Continuar"
        secondButtonLabel="Cancelar"
        handleSubmit={handlePhoneNumberVerifyForm}
        showSecondButton
        handleSecondButton={backToInitialPage}
      />
    ),
    contrasena: (
      <PasswordChangeForm
        title="Cambiar contraseña"
        handleSubmit={handlePasswordForm}
        handleCancel={backToInitialPage}
      />
    )
  }

  return (
    <Container className="px-5 md:px-20 pt-8 md:pt-14 pb-14 md:pb-18">
      <nav className="flex font-bold text-lg text-blue-700 mb-7.5 md:mb-10 cursor-pointer" onClick={() => navigate(-1)}>
        <ReactSVG src={ArrowBackIcon} className="pr-3.25" /> Regresar
      </nav>
      {pageForms[type]}
    </Container>
  )
}
