import React from 'react'
import { useField } from 'react-final-form'

import { FormControlLabel, withStyles } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { marginSizes } from '../../utils/sizes'

const AntSwitch = withStyles(theme => ({
  root: {
    width: 40,
    height: 20,
    padding: 0,
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(21px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#0056AC',
        borderColor: '#0056AC'
      }
    }
  },
  thumb: {
    width: 15,
    height: 15,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: '#A8A9AB',
    borderColor: '#A8A9AB'
  },
  checked: {}
}))(Switch)

const SwitchField = ({ label, name, validate, description, parentClassName, margin = 'normal', ...props }) => {
  const { input } = useField(name, { validate, type: 'checkbox', ...props })

  return (
    <section className={clsx('flex flex-col', parentClassName, marginSizes[margin])}>
      {label && (
        <label htmlFor={`${name}-input`} className="font-bold mb-2.5">
          {label}
        </label>
      )}
      <FormControlLabel
        control={
          <AntSwitch
            id={`${name}-input`}
            {...input}
            {...props}
            onChange={e => {
              input.onChange(e.target.checked)
              props.onChange && props.onChange(e.target.checked)
            }}
          />
        }
        classes={{ root: '!mx-0', label: '!ml-2 !text-sm !font-medium !tracking-normal' }}
        label={description}
      />
    </section>
  )
}

export default SwitchField

SwitchField.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
  validate: PropTypes.func,
  description: PropTypes.string,
  parentClassName: PropTypes.string,
  margin: PropTypes.string,
  onChange: PropTypes.func
}
